<template>
  <v-card>
    <v-toolbar color="primary" dark dense>
      <v-toolbar-title
        >Asignar instaladora a estudio {{ estudio }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn @click.stop="$emit('close')" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="pa-3">
      <div class="d-flex align-center">
        <span class="text-h6 mr-4">Estudio: {{ estudio }}</span>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          dense
          hide-details="auto"
          solo
          v-model="search"
        />
      </div>
      <!-- <v-list dense>
            <v-list-item-group>
              <v-list-item
                @click.stop="removeInstaladora(inst)"
                v-for="(inst, i) in value"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    inst.nombreInstaladora
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    inst.desciption
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn small icon outlined color="error">
                    <v-icon small>mdi-close</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <v-divider></v-divider>
          <v-list dense>
            <v-list-item-group>
              <v-list-item
                @click.stop="addInstaladora(inst)"
                v-for="(inst, i) in instaladoras"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title>{{
                    inst.nombreInstaladora
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    inst.desciption
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn small icon outlined color="success">
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list> -->
      <v-list dense>
        <v-list-item-group>
          <v-divider />
          <template v-for="(inst, i) in instaladorasFiltrados">
            <v-list-item @click.stop="addInstaladora(inst)" :key="i">
              <v-list-item-content>
                <v-list-item-title>{{
                  inst.nombreInstaladora
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  inst.desciption
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`${i}-divider`"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-btn @click.stop="removeInstaladora" color="error" small>
          <v-icon left>mdi-close</v-icon>
          Desasignar instaladora
        </v-btn>
      </div>
    </div>
    <ConfirmDialog ref="confirm" />
  </v-card>
</template>

<script>
import { parseDate } from "@/utils/index.js";

export default {
  props: {
    value: Array,
    estudio: Number,
  },
  components: {
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
  },
  data() {
    return {
      edit: false,
      instaladoras: [],
      search: null,
    };
  },
  computed: {
    instaladorasFiltrados() {
      if (!this.search) return this.instaladoras;
      return this.instaladoras.filter((c) =>
        c.nombreInstaladora.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    parseDate,
    async getInstaladoras() {
      this.instaladoras = [];
      const { data } = await axios(
        `${process.env.VUE_APP_API_URL}/instaladoras?idEstudio=${this.estudio}`
      );
      this.instaladoras = data;
    },
    async addInstaladora(inst) {
      if (
        !(await this.$refs.confirm.open(
          "Asignar a instaladora",
          "¿Estás seguro de que quieres asignar este estudios a " +
            inst.nombreInstaladora +
            "?"
        ))
      )
        return;
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/instaladoras/add_to_estudio`,
        data: { idEstudio: this.estudio, idInstaladora: inst.idInstaladora },
      });
      this.$emit("reload");
      this.getInstaladoras();
    },
    async removeInstaladora() {
      if (
        !(await this.$refs.confirm.open(
          "Desasignar instaladora",
          `Seguro que quieres desasignar este estudio de su instaladora`
        ))
      )
        return;
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/instaladoras/add_to_estudio`,
        data: { idEstudio: this.estudio, idInstaladora: null },
      });
      this.$emit("reload");
      this.getInstaladoras();
    },
  },
  mounted() {
    this.getInstaladoras();
  },
};
</script>

<style></style>
